.my-strengths {
    .testresults-boxes__box__content {
        margin-left: 0;

        &:not(:last-of-type) {
            margin-bottom: 15px;
        }

        .strength-name {
            padding: 0;
        }

        .progress {
            height: 30px;
            width: 100%;

            &-bar {
                background-color: #ff0000;
                height: 100%;
                line-height: 30px;
                white-space: nowrap;
            }
        }
    }
}
