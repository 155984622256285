$font_museo_100: 'museo-sans',
'Museo Sans',
'Museo Sans 100',
Arial,
sans-serif;

$font_museo_700: 'museo-sans',
'Museo Sans',
'Museo Sans 700',
Arial,
sans-serif;

$color_3: #000000;
$orange: #ed022a;
body {
    margin: 0;
    padding: 0;
}

#box-links {
    .header-img {
        text-transform: capitalize;
        margin-bottom: 20px;

        .single-box {
            height: 220px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            border: 1px solid rgba(0,0,0,.3);
            padding: 0;
            margin: -1px -1px 0 0;
        }

        .label {
            color: #000000;
            text-shadow: 1px 1px #333333;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            flex: 1;
        }

        .column-one-half {
            padding-right: 0;
        }

        .box-img {
            height: 200px;
            width: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: top 20px center;
            flex: 2;
        }

        .products-img {
            background-image: url('../img/product.png');
        }

        .market-trends-img {
            background-image: url('../img/market_trends.png');
        }

        .company-img {
            background-image: url('../img/company.png');
        }

        .sales-img {
            background-image: url('../img/sales_techniques.png');
        }

        .test-img {
            background-image: url('../img/test.png');
        }

        .discuss-img {
            background-image: url('../img/discuss.png');
        }


        .profile-badge-img {
            background-image: url('../img/profile-badge.png');
        }

        .profile-clipboard-img {
            background-image: url('../img/profile-clipboard.png');
        }

        .profile-dumbbell-img {
            background-image: url('../img/profile-dumbbell.png');
        }

        .profile-group-img {
            background-image: url('../img/profile-group.png');
        }

        .profile-watch-img {
            background-image: url('../img/profile-watch.png');
        }
    }
}

#page-product-detail {
    .product-title {
        margin-bottom: 40px;
    }

    .product-content {
        margin-bottom: 20px;
        padding-top: 20px;
    }

    #section_parameters {
        .parameter-box {
            img {
                width: 100%;
            }
        }
    }
}

.product-content {
    border-top: 1px solid #dcdcdc;

    table {
        width: 100% !important;
    }

    .product-hider {
        cursor: pointer;
    }

    .accordion-button {
        position: relative;
        padding-left: 22px;
    }

    .accordion-button:before {
        background: transparent url("../img/accordion-arrows.png") 5px 0 no-repeat;
        content: '';
        left: 0;
        display: block;
        height: 12px;
        width: 12px;
        position: absolute;
        margin-top: 6px;
    }

    .accordion-button.active-arrow:before {
        background-position: 0 -12px;
        height: 18px;
        margin-top: 8px;
    }
}

.design-options {
    height: auto;
    position: relative;
    margin-top: 20px;

    &__list {
        padding: 0;
        margin: 0;
        list-style-type: none;

        &__item {
            &.active {
                background: unset;

                .design-options__list__item__name {
                    font-family: $font_museo_100;
                    color: $orange;
                }

                .design-options__list__item__boxes {
                    display: block;
                }
            }

            &__name {
                width: 25%;
                color: $color_3;
                font-family: $font_museo_100;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 20px;
                cursor: pointer;
            }

            &__boxes {
                display: none;
                width: 75%;
                float: right;
                position: absolute;
                right: 0;
                top: 0;
                min-height: 200px;

                &:after {
                    display: table;
                    content: ' ';
                    clear: both;
                }

                &__content {
                    margin: 0 -25px;
                    display: flex;
                    flex-wrap: wrap;
                    padding-bottom: 30px;
                }
            }
        }
    }
}

.product-resources {
    &__download {
        margin-left: 25px;
        width: 100%;

        &__text {
            display: inline-block;
            margin-left: 20px;
            margin-bottom: 10px;
            font-size: 16px;
            color: $color_3;
            font-weight: 600;
            transition: color .3s ease;

            &:hover {
                color: $orange;
            }
        }
    }
}

.specification-box {
    display: flex;
    flex-wrap: wrap;
    width: calc(25% - 50px);
    margin: 0 25px 20px 25px;

    &:hover {
        cursor: pointer;

        .specification-box__text {
            border-color: $orange;
            transition: border-color .3s ease;
        }
    }

    .progressive {
        background-color: #fff;
    }

    &__image {
        margin: 0 0 20px 0;
        height: auto;
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__text {
        padding-top: 20px;
        width: 100%;
        transition: border-color .3s ease;
        border-top: 2px solid $color_3;

        &__title {
            color: $color_3;
            font-family: $font_museo_100;
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
            word-break: break-word;
        }

        &__subtitle {
            color: $color_3;
            font-family: $font_museo_100;
            font-size: 14px;
            text-transform: uppercase;
            word-break: break-word;
        }
    }
}

.video-player {
    width: 100%;

    .video-js {
        .vjs-big-play-button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.gray-background {
    background-color: #f5f5f5;
}

li {
    line-height: 1.6;
}

.news-boxes {
    margin: 0;
    width: 100%;

    &__box {
        margin-bottom: 30px;
        float: left;
        margin-left: 30px;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        width: 300px;
        height: 375px;
        overflow: hidden;

        &:first-child,
        &:nth-child(3n+1) {
            margin-left: 0;
        }

        a {
            width: 100%;
            height: 100%;
            text-decoration: none;
        }

        &:after {
            display: table;
            content: ' ';
            clear: both;
        }

        &:hover {
            .news-boxes__box__redirection {
                visibility: visible;
                opacity: 1;
                transition: visibility .4s, opacity .4s ease;
            }
        }

        &__image {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: top .4s ease;
            position: relative;
            height: 230px;
            width: 100%;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__redirection {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 100px;
            color: #ffffff;
            width: 105px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            background-color: $orange;
            font-family: $font_museo_100;
            font-weight: 700;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 1px;
            transition: border-right .2s, width .2s ease;
            border-right: 0px solid #8a0b20;
            opacity: 0;
            visibility: hidden;
            transition: visibility .4s, opacity .4s ease;
        }

        &__text {
            height: 145px;
            background: #fff;
            z-index: 5;
            width: 100%;
            padding: 18px;

            &__category {
                font-family: $font_museo_100;
                font-weight: 700;
                color: $orange;
                font-size: 12px;
                letter-spacing: 1px;
                text-transform: uppercase;
            }

            &__title {
                font-weight: 700;
                color: #1c1417;
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 10px;
                min-height: 48px;
            }

            &__lead {
                display: none;
                font-weight: 300;
                color: #1c1417;
                opacity: .8;
                font-size: 14px;
                line-height: 25px;
            }

            &__date {
                font-weight: 300;
                color: #1c1417;
                font-size: 12px;
                font-style: italic;
                margin-bottom: 8px;
            }

            a {
                text-decoration: none;
            }
        }
    }
}

.leader-boxes {
    margin: 0;
    width: 100%;

    &__box {
        margin-bottom: 30px;
        float: left;
        margin-left: 30px;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        width: 100%;
        height: 100px;
        overflow: hidden;

        a {
            width: 100%;
            height: 100%;
            text-decoration: none;
        }

        &:after {
            display: table;
            content: ' ';
            clear: both;
        }

        &:hover {
            .news-boxes__box__redirection {
                visibility: visible;
                opacity: 1;
                transition: visibility .4s, opacity .4s ease;
            }
        }

        &__image {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: top .4s ease;
            position: relative;
            height: auto;
            width: auto;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__content {
            display:flex;
            align-items: center;
            height: 100%;
            width: 64.5%;
            overflow: hidden;
            margin-left:10px;
            &__title {
                height: 20px;
                width: 100%;
                overflow: hidden;
            }
            &__score {
                height: 20px;
                width: 100%;
                overflow: hidden;
            }
        }
    }
}

.test-boxes {
    margin: 0;
    width: 100%;

    &__box {
        margin-bottom: 20px;
        padding: 20px;
        float: left;
        position: relative;
        width: 100%;
        overflow: hidden;
        background-color: #fff;

        .btn {
            width: auto;
            text-decoration: none;
            position: absolute;
            top: 20px;
            right: 20px;
        }

        &:after {
            display: table;
            content: ' ';
            clear: both;
        }

        &:hover {
            .news-boxes__box__redirection {
                visibility: visible;
                opacity: 1;
                transition: visibility .4s, opacity .4s ease;
            }
        }

        h3 {
            padding-top: 0;
        }

        &__image {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: top .4s ease;
            position: relative;
            height: auto;
            width: 32.5%;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__content {
            display:flex;
            align-items: center;
            height: 100%;
            width: 64.5%;
            overflow: hidden;
            margin-left:10px;
            &__title {
                height: 20px;
                width: 100%;
                overflow: hidden;
            }
            &__score {
                height: 20px;
                width: 100%;
                overflow: hidden;
            }
        }

        &-description {
            margin-bottom: 0;
        }
    }
}

.testresults-boxes {
    margin: 0;
    width: 100%;

    &__box {
        margin-bottom: 20px;
        padding: 30px 20px;
        float: left;
        margin-left: 0;
        position: relative;
        width: 100%;
        overflow: hidden;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;

        a {
            width: 100%;
            height: 100%;
            text-decoration: none;
        }

        &:after {
            display: table;
            content: ' ';
            clear: both;
        }

        &__image {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: top .4s ease;
            position: relative;
            height: auto;
            width: 32.5%;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__content {
            align-items: center;
            height: 100%;
            width: 100%;
            overflow: hidden;
            margin-left: 10px;
            &__date {
                font-weight: 300;
                color: #1c1417;
                font-size: 12px;
                font-style: italic;
                margin-bottom: 10px;
            }
            &__title {
                float:left;
                color: #C3512F;
                font-weight: 800;
                font-size: 16px;
            }
            &__score {
                float:right;
                font-weight: 800;
            }

            &.post {
                border: 0;
                margin-left: 0;
                padding-bottom: 0;
                width: 50%;

                h2 span {
                    white-space: nowrap;
                }

                &:not(:last-of-type) {
                    margin-bottom: 15px;
                }

            }
        }
    }
}
.userprofile-container {
    width: 100%;
    &__box {
        display: inline-block;
        position: relative;
        width: 30%;
        height:150px;
    }

}

.footer {
    height: auto;
    background: #fff;
    white-space: nowrap;
    padding-bottom: 20px;

    .site-container {
        padding: 0 20px;
    }

    a {
        color: #4f4b45;
        text-decoration: none;

        &:hover {
            color: $orange;
        }
    }

    &__bar {
        height: 10px;
        width: 100%;
        background: url('../images/bar-lightblue.svg') no-repeat;
    }

    &__text {
        color: #000000;
        font-family: $font_museo_100;
        font-weight: 700;
        font-size: 24px;
        line-height: 40px;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.86px;
        margin: 40px 0 10px;
    }

    &__sitemap {
        color: #4f4b45;
        font-family: $font_museo_100;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.75px;
        display: inline-block;
        width: 50%;
        text-transform: uppercase;
        white-space: normal;
        cursor: pointer;

        &:hover {
            color: $orange;
        }

        &__title {
            margin-top: 20px;
            font-family: $font_museo_100;
            font-weight: 500;
            color: #1c1417;
            font-size: 16px;
            line-height: 24px;
            text-transform: uppercase;
            letter-spacing: 0.45px;
            margin-bottom: 10px;
        }

        &__item {
            font-family: $font_museo_100;
            font-weight: 500;
            color: #1c1417;
            opacity: 0.7;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: 0.26px;

            &:last-child {
                margin-bottom: 30px;
            }
        }
    }

    &__flag {
        margin-top: 24px;
        float: right;

        img {
            max-width: 17px;
        }
    }

    &__copyrights {
        color: #4f4b45;
        font-family: $font_museo_100;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.75px;
        float: left;
        width: 35%;
        white-space: normal;
    }

    &__data {
        color: #4f4b45;
        font-family: $font_museo_100;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.75px;
        display: inline-block;
        float: right;
        width: 35%;
        white-space: normal;

        &__mail {
            white-space: normal;
            float: right;
        }

        &__number {
            white-space: normal;
            float: right;
            margin-left: 20px;
        }

        &__chat {
            white-space: normal;
            float: right;
            margin-left: 20px;
        }
    }

    &__contact {
        color: #4f4b45;
        white-space: normal;
        font-family: $font_museo_100;
        font-weight: 500;
        width: 50%;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.75px;
        text-transform: uppercase;
        display: inline-block;
        text-align: right;
        padding-right: 5px;
    }

    &__social {
        display: flex;
        justify-content: flex-end;

        a {
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }
        }

        &__icon {
            height: 16px;
            transition: background-image 0.3s ease;

            &--linkedin {
                width: 16px;
                background: url('../images/icon-social-new-linkedin.svg') no-repeat center center;

                &:hover {
                    background: url('../images/icon-social-new-linkedin-orange.svg') no-repeat center center;
                }
            }

            &--twitter {
                width: 16px;
                background: url('../images/icon-social-new-twitter.svg') no-repeat center center;

                &:hover {
                    background: url('../images/icon-social-new-twitter-orange.svg') no-repeat center center;
                }
            }

            &--pinterest {
                width: 14px;
                background: url('../images/icon-social-new-pinterest.svg') no-repeat center center;

                &:hover {
                    background: url('../images/icon-social-new-pinterest-orange.svg') no-repeat center center;
                }
            }

            &--instagram {
                width: 16px;
                background: url('../images/icon-social-new-instagram.svg') no-repeat center center;

                &:hover {
                    background: url('../images/icon-social-new-instagram-orange.svg') no-repeat center center;
                }
            }

            &--facebook {
                width: 10px;
                background: url('../images/icon-social-new-facebook.svg') no-repeat center center;

                &:hover {
                    background: url('../images/icon-social-new-facebook-orange.svg') no-repeat center center;
                }
            }
        }
    }
}

.footer-sitemap-container {
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: all .3s ease;

    ul {
        list-style-type: none;
        padding: 0;
    }

    &.active {
        max-height: 500px;
        background-color: #fff;
    }
}

@media (max-width: 767px) {
    .footer {
        text-align: center;
        height: auto;
        padding-bottom: 40px;
        white-space: normal;

        &__contact {
            text-align: left;
            float: left;
            padding: 0;
        }

        .mobile-order {
            display: flex;
            flex-wrap: wrap;
        }

        &__text {
            font-family: $font_museo_100;
            font-weight: 700;
            margin-bottom: 40px;
        }

        &__social {
            margin-top: 0;
            width: 100%;
        }

        &__sitemap {
            margin: 0;
            text-align: right;
            float: left;
            padding-right: 20px;
            margin-bottom: 10px;

            &__title {
                margin-top: 0;
            }

            &__item {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &__flag {
            float: right;
            position: absolute;
            top: 6px;
            right: 0;
            left: 0;
            margin-left: 200px;
            margin-top: 0;
        }

        &__copyrights {
            width: 180px;
            order: 2;
            margin: 0 auto;
        }

        &__data {
            order: 1;
            width: 100%;
            margin-top: 10px;

            &__chat {
                display: none;
            }

            &__number {
                width: 100%;
                margin: 0;
            }

            &__mail {
                width: 100%;
            }
        }
    }
    .footer-sitemap-container.active {
        margin-bottom: 10px;
    }
}

.wysiwyg-heading {
    font-family: $font-museo-700 !important;
    color: #000000 !important;
    font-size: 30px !important;
    line-height: 40px;
    margin-bottom: 50px;
}

.wysiwyg-heading-orange {
    font-family: $font-museo-700 !important;
    color: $orange !important;
    font-size: 30px !important;
    line-height: 40px;
    margin-bottom: 50px;
}
.filter-form {
    margin-top:20px;
    h5 {
        font-size: 1.1em;
        margin: 0;
        padding: 15px 0;
    }
    .inner-wrapper {
        width:50%;
        margin-bottom:20px;
        padding-left:20px;
        .date-container {
            width:49%;
            position:relative;
            display:inline-block;
            input {
                width:80%;
            }
        }
        .row {
            width:100%;
            position:relative;
            display:flex;
            margin-bottom:10px;
            align-items:center;
            justify-content: space-between;
            label {
                display:flex;
            }
        }
    }
}
.discussions-select2 {
    display:flex;
}
.discussion-results {
    h3 {
        margin-bottom: 15px;
    }
}

.page-title {
    border-bottom: 1px solid #ccc;
    margin-bottom: 25px;
    text-align: center;
}
.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.testresults-pagination {
    display:flex;
    width:100%;
    justify-content: center;
}
#id_open_answer-error {
    color: #ed022a;
}
.hidden {
    display:none
}
