.question {
    &__answer {
        ul {
            padding-left: 0;
            list-style: none;
        }

        li {
            margin-bottom: 10px;
        }

        label {
            padding-left: 28px;
            position: relative;

            input[type='checkbox'],
            input[type='radio'] {
                height: 0;
                left: -99999px;
                opacity: 0;
                position: absolute;
                width: 0;

                &:checked + .checkmark::after {
                    opacity: 1;
                }
            }

            .checkmark {
                &::after {
                    content: '✓';
                    display: block;
                    font-size: 16px;
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    transform: translate3d(-50%, -50%, 0);
                    transition: opacity .1s;
                    opacity: 0;
                }

                &::before {
                    border: 1px solid #000;
                    content: '';
                    display: block;
                    height: 16px;
                    left: 0;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 16px;
                }
            }
        }
    }
}
