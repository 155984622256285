.profile {
    .team .column-one-quarter {
        display: block !important;
    }

    .box-texts {
        align-items: center;
        color: #000;
        display: flex;
        font-size: 21px;
        height: 100%;
        justify-content: center;
        text-align: center;
        width: 100%;
    }
}
